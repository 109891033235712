import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary:{
            main: "#6417e8"
        },
        secondary:{
            main: "#1E2A5E"
        },
        complement:{
            main: "#E1D7B7"
        }
    }
});