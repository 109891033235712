import { Box } from '@mui/material';
import Navbar from './components/Navbar';
import TempContent from './components/TempContent';

function App() {
  return (
    <Box>
      <Navbar/>
      <TempContent/>
    </Box>
  );
}

export default App;
