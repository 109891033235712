import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import logo from '../images/text-logo.png';
import { red } from '@mui/material/colors';


const TempContent = () => {
    return (
        <Stack direction="row" display="flex" spacing={2}  p={6} justifyContent="center">
        <Stack direction="column" display='flex' spacing={2} justifyContent="center">
            <Box
                component="img"
                sx={{
                    height: 233,
                    width: 350,
                }}
                alt="CxGrading Logo"
                src={logo}
            />
            <Typography variant='h5'>
                Objective Grading, Objectively Better.
            </Typography>
            <Typography variant='h4' textAlign="center">
                Coming Soon
            </Typography>
        </Stack>
        </Stack>
    );
}

export default TempContent;